let config = {
  emailjs: {
    serviceID: "service_24c89dk",
    templateID: "template_qnyysen",
    userID: "5VCZfGbAC6iW9o7GB"
  }
};



export default config;
