<template>
  <div :class="{ 'bg-white': !nightMode, 'bg-dark': nightMode }" class="p-st">
    <div class="container py-4">
      
      <div class="text-center" data-aos="fade" data-aos-once="true" data-aos-duration="1000">
        <span class="title text-center" :class="{ pgray: !nightMode, 'text-light': nightMode }" >Expertise</span>
      </div>
      <hr width="50%" :class="{ pgray: !nightMode, 'bg-secondary': nightMode }"/>

      <br />

      <div class="row">
        
                        <div
                          class="col-xl-4 col-bg-4 col-md-4 col-sm-12 text-center pb-5 px-4"
                          v-for="(skill, idx) in skills"
                          :key="skill.title"
                          data-aos="fade-up"
                          data-aos-offset="10"
                          data-aos-delay="30"
                          :style="{ 'transition-delay': idx / 4.2 + 's' }"
                          data-aos-duration="500"
                          data-aos-easing="ease-in-out"
                          data-aos-mirror="true"
                          data-aos-once="true"
                        >

                        <div class="bg-div"><i :class="skill.icon"></i></div>
                        
                        <div class="title2 pt-2">{{ skill.title }}</div>
                        <hr v-if="skill.info.length"
                          width="50%"
                          :class="{ pgray: !nightMode, 'bg-secondary': nightMode }"
                        />
                        <span class="title3">{{ skill.info.join(", ") }}</span>

                        </div>




      </div>
    </div>
  </div>
</template>


<script>
import info from "../../info";

export default {
  name: "Skills",
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      skills: info.skills,
    };
  },
};
</script>

<style scoped>
.title {
  font-size: 30px;
  font-weight: 500;
  color:  rgb(70, 43, 43);
  text-shadow: 1px 1px 2px rgb(71, 71, 71);
  font-style: italic;
}
.title1 {
  font-size: 24px;
  font-weight: 400;
  
}

.title2 {
  font-size: 20px;
  font-weight: 500;
  color: rgb(6, 37, 37);
  font-style: italic;
}

.title3 {
  font-size: 16px;
  font-weight: 400;
  color: rgb(25, 48, 48);
}

.fa {
  color: rgb(212, 149, 97);
  font-size: 40px;
  transition: all 0.5s;
}

.fas {
  color: rgb(212, 149, 97);
  font-size: 40px;
  /* font-weight: bold; */
  transition: all 0.5s;
}


.fa {
  color: rgb(212, 149, 97);
  font-size: 40px;
  transition: all 0.5s;
}

.fab {
  color: rgb(212, 149, 97);
  font-size: 40px;
  transition: all 0.5s;
}


.p-st {
  background-image: url('@/assets/Image_res/back02.webp');
  background-size: cover; /* Adjust as needed */
  background-repeat: no-repeat; /* Adjust as needed */
  background-attachment: fixed; /* Adjust as needed */
  position: static;
  /* background: rgba(200, 200, 200, 0.5); */
  border-style: groove;
  border-color:  rgb(255, 255, 255);
  border-width: 0.5px;
  
}

/* Text color changes for dark theme */
.bg-dark .title
{
  color: rgb(2, 207, 207);
}

.bg-dark hr.bg-secondary,
.bg-dark .title2,
.bg-dark .title3 {
  color: rgb(255, 255, 255); 
}
.bg-dark.p-st
{
  background-image: url('@/assets/Image_res/back_dark_.webp');
  /* Set background properties */
  background-size: cover; /* Adjust as needed */
  background-repeat: no-repeat; /* Adjust as needed */
  background-attachment: fixed; /* Adjust as needed */
  position: static;
  border-style: groove;
  border-color:  rgb(82, 81, 81);
  border-width: 0.5px;
}
</style>
