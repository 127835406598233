
let info = {
  name: "Dixit Prajapati",
  logo_name: "Dixit P",
  // flat_picture: require("./src/assets/potrait.jpg"),
  config: {
    use_cookies: true,
    navbar: {
      blur: false
    }
  },
  description:
  "Presenting myself as an accomplished professional in the world of technology, I bring a wealth of experience and a passion for innovation. My career is marked by a consistent dedication to excellence. I have a proven track record of delivering solutions that drive progress, whether it's streamlining systems or crafting data-driven insights that empower decision-makers. My true enthusiasm lies in the boundless potential of technology to uncover valuable insights, fueling business growth and sparking innovation. I eagerly anticipate the opportunity to collaborate with dynamic teams, where our collective efforts will undoubtedly lead to the creation of pioneering solutions and a brighter future.",
  links: {
    linkedin: "https://www.linkedin.com/in/dixit-prajapati",
    // github: "",
    resume: "https://github.com/DixitPrajapatii/blob/blob/4247b80940ccf0f5b5bd36a7151b9b5fe64693df/Dixit_Prajapati.pdf"
    
  },
  education: [
    {
      name: "Weltec Institute",
      place: "Gujarat, India",
      date: "Nov, 2021 - Mar, 2022",
      degree: "Data Analytics",
      gpa: "8.5",
      description:"",
      skills: [
        "Database",
        "SQL",
        "Python",
        "Excel"
      ]
    },
    {
      name: "Parul University",
      course:"Bachelor of Science in Agriculture",
      place: "Gujarat, India",
      date: "June, 2016 - June, 2020",
      degree: "Bachelor of Science in Agriculture",
      gpa: "8.3",
      description:"",
      // skills: [
      //   "OOP",
      //   "MYSQL",
      //   "Github",
      //   "Bit of C/C++",
      //   "HTML",
      //   "CSS",
      //   "Python",
      //   "Java",
      //   "SQL",
      //   "Oracle",
      //   "PHP"
      // ]
    }
  ],
  experience: [

    {
      name: "SunflowerLab",
      place: "Gujarat, India",
      position: "Software Engineer",
      date: "Nov 2022 - Sept 2023",      
      description:"",
      skills: [
        "Python",
        "SQL",
        "MYSQL",
        "Postgres SQL",
        "SSIS",
        "Data Analysis",
        "ETL",
        "Robotic Process Automation",
        "Jasper Reports",
        "Performance Tuning"        
      ]
    },
    {
      name: "Exxat",
      place: "Gujarat, India",
      position: "Product Analyst",
      date: "Feb 2022 - Nov 2022",      
      description:"",
      
      skills: [
        "SQL",
        "Python",
        "Databases",
        "Debugging",
        "Cosmos DB",
        "Azure",
        "ETL"

      ]
    }
  ],
  skills: [
    {
      title: "Languages",
      info: [
        "Python",
        "SQL",
        "R"
      ],
      icon: "fa fa-code" 
    },
    // {
    //   title: "Big Data Frameworks",
    //   info: [
    //     "Spark",
    //     "Hadoop"

    //   ],
    //   icon: "fa fa-cubes"
    // },
    {
      title: "NoSQL Databases",
      info: ["Azure Cosmos DB"],
      icon: "fas fa-laptop-code"
    },
  
    // {
    //   title: "Azure Cloud",
    //   info: ["Databricks","Data Factory", "Synapse Analytics", "SQL Database", "Data Lake Storage gen2", "Blob Storage", "Active Directory"],
    //   icon: "fa fa-cloud"
    // },

    {
      title: "SQL Databases",
      info: ["MySQL", "PostGreSQL", "SQL Server"],
      icon: "fa fa-database"
    },


    // {
    //   title: "Orchestration Tools",
    //   info: [
    //     "Power Bi",
    //     "Jasper"
    //   ],
    //   icon: "fas fa-tools"
    // },

    // {
    //   title: "",
    //   info: [
    //   ],
    //   icon: ""
    // },

    {
      title: "Other",
      info: [
        "Data Warehouse",
        "Data Modeling",
        "strong verbal and written communication",
        "ETL",
        "ELT",
        "SSIS",
        "Power BI",
        "Jasper"
      ],
      icon: "fa fa-feather"
    },

    {
      title: "",
      info: [
      ],
      icon: ""
    },

  ],
  certificates: [

    {
      name: "Python for Data Science, AI & Development",
      pictures: [
        {
          img: require("./src/assets/certificates/general/PythonforData ScienceAIDevelopment.jpg")
        }
      ],
      technologies: [],
      category: "",
      visit: "https://www.coursera.org/account/accomplishments/certificate/N6NC243LPX2N",
      description:""
    },

    {
      name: "SQL(Intermediate)",
      pictures: [
        {
          img: require("./src/assets/certificates/general/sql_intermediate_hcrank.jpg")
        }
      ],
      technologies: [],
      category: "",
      github: "",
      date: "",
      visit: "https://www.hackerrank.com/certificates/7e13642177e1",
      description:  "",
    },
    {
      name: "SQL (Basic)",
      pictures: [
        {
          img: require("./src/assets/certificates/general/sql_basic_hcrank.jpg")
        }
      ],
      technologies: [],
      category: "",
      github: "",
      date: "",
      visit: "https://www.hackerrank.com/certificates/971bdd1349e6",
      description:  "",
    },
    {
      name: "Data Storage in Microsoft Azure",
      pictures: [
        {
          img: require("./src/assets/certificates/general/DatastorageinMicrosoftAzure.jpg")
        }
      ],
      technologies: [],
      category: "",
      github: "",
      date: "",
      visit: "https://www.coursera.org/account/accomplishments/certificate/APZX62U82RXZ",
      description:  "",
    },
    {
      name: "Microsoft Azure for Data Engineering",
      pictures: [
        {
          img: require("./src/assets/certificates/general/MicrosoftAzureforDataEngineering.jpg")
        }
      ],
      technologies: [],
      category: "",
      github: "",
      date: "",
      visit: "https://www.coursera.org/account/accomplishments/certificate/VP9XQGLP9RFP",
      description:  "",
    },
    {
      name: "Relational Database Administration (DBA)",
      pictures: [
        {
          img: require("./src/assets/certificates/general/RelationalDatabaseAdministration.jpg")
        }
      ],
      technologies: [],
      category: "",
      github: "",
      date: "",
      visit: "https://www.coursera.org/account/accomplishments/certificate/DAPZBP7QSX6J",
      description:  "",
    },
    {
      name: " Introduction to Relational Databases (RDBMS)",
      pictures: [
        {
          img: require("./src/assets/certificates/general/IntroductiontoRelationalDatabases.jpg")
        }
      ],
      technologies: [],
      category: "",
      github: "",
      date: "",
      visit: "https://www.coursera.org/account/accomplishments/certificate/ZD7TYVB6BASR",
      description:  "",
    },
    {
      name: "Databases and SQL for Data Science with Python (with Honors)",
      pictures: [
        {
          img: require("./src/assets/certificates/general/DatabasesandSQ fordataSciencewithPython.jpg")
        }
      ],
      technologies: [],
      category: "",
      github: "",
      date: "",
      visit: "https://www.coursera.org/account/accomplishments/certificate/TT69TJBK2B9Q",
      description:  "",
    },
    {
      name: "Introduction to Data Engineering ",
      pictures: [
        {
          img: require("./src/assets/certificates/general/IntroductiontoDataEngineering.jpg")
        }
      ],
      technologies: [],
      category: "",
      github: "",
      date: "",
      visit: "https://www.coursera.org/account/accomplishments/certificate/Z457MTU8QJNK",
      description:  "",
    },
    {
      name: "Data Analysis with R Programming",
      pictures: [
        {
          img: require("./src/assets/certificates/general/DataAnalysiswithRProgramming.jpg")
        }
      ],
      technologies: [],
      category: "",
      github: "",
      date: "",
      visit: "https://www.coursera.org/account/accomplishments/certificate/96EBNFQBKDF4",
      description:  "",
    },
    {
      name: " Foundations: Data, Data, Everywhere",
      pictures: [
        {
          img: require("./src/assets/certificates/general/FoundationsDataDataEverywhere.jpg")
        }
      ],
      technologies: [],
      category: "",
      github: "",
      date: "",
      visit: "https://www.coursera.org/account/accomplishments/certificate/KFHPUPYSNHBT",
      description:  "",
    },
    {
      name: "SQL (STRUCTURED QUERY LANGUAGE)",
      pictures: [
        {
          img: require("./src/assets/certificates/general/SQL.jpg")
        }
      ],
      technologies: [],
      category: "",
      github: "",
      date: "",
      visit: "",
      description:  "",
    }
  ],
   recommendations: [

    {
      title:"Extremely curious person with unbelievable degree to learn new stuff and excel in no time that's how I would describe Dixit. I'd definitely recommend him to any firm because he has got really demonstrable capabilities which goes well beyond his resume. Highly recommend.",
      author: "Jaydip Barvaliya",
      position: "Senior Software Engineer",
      company: "Cognizant",
      location: "Toronto, Canada"
    },

    {
      title:"I highly recommend Dixit and would love to work together again.Dixit is amazing at his job! He knows his way around people, he is good with the clients, does whatever it takes to help colleagues and gets things done. He makes sure that everyone is on the same page and focused on the main goal.",
      author: "Nilam Zala",
      position: "Customer Success Manager",
      company: "Automation Anywhere",
      location: "India"
    },
    
    
    {
      title:" It’s rare that you come across standout talent like Dixit Prajapati. It’s without hesitation that I recommend Dixit Prajapati, if you are looking for the best person for Analysing Data because of his amazing skills of Python, SQL and Analysing Data. He is a well connected professional that always takes the time to support anyone is his network. He has a very impressive background and profile and I recommend Dixit as a expert to connect with and consider for anything appropriate.",
      author: "Vishal Barvaliya",
      position: "Data Engineer",
      company: "TD Bank",
      location: "Toronto, Canada"
    },
    
    
    {
      title:"Dixit is hard working, dedicated and foreward thinking person. We always rely on Dixit in group project for this problem solving ability, data analysis and IT skills. He always would like stay in forefront of technology. If you are looking for a Data Analyst he is your man!",
      author: "Piyush Vaghela",
      position: "QA Professional",
      company: "",
      location: "Toronto, Canada"
    },
    
    {
      title:"Dixit is good with Product analysis and have a strong knowledge of Sql.He is a good person to work with and always have a ready to help nature.",
      author: "Abhishek Rathwa",
      position: "Customer Support Specialist",
      company: "Navaera",
      location: "Gujarat, India"
    },
    {
      title:"Very good in SQL and Python. Always a supportive team member and a good talent across team. He has an attractive impression within a team as he has good amount of knowledge of different IT tools and languages.",
      author: "Siddharth Parmar",
      position: "Product Analyst",
      company: "Exxat",
      location: "Gujarat, India"
    },
    
    {
      title:"We’ve joined our hands on several projects, and Dixit is one of the best people I had as a partner. I highly recommend his expertise to any person looking for Data Analytics. He is the most profound person I have met, and his ability to tackle any problem is remarkable and with a warm smile. Dixit would become an appreciated member of any team.",
      author: "Sumit Shandilya",
      position: "",
      company: "Centennial College",
      location: "Toronto, Canada"
    },

    {
      title:"Dixit is powerful, knowledgeable and responsible data analyst. I would recommend him with any project that requires the very best in analysis execution. Dixit possesses a winning combination of solid tech skills and business sense, I learned a great deal from him. He definitely shines in a a busy environment. Definitely worth recommending. ",
      author: "Kevin Barvaliya",
      position: "",
      company: "St. Clair College",
      location: "Toronto, Canad"
    },
    {
      title:"Dixit is very knowledgeable and quick learner. He is good at python programming.",
      author: "Abhee Sheth",
      position: "Technology Analyst",
      company: "Infosys",
      location: "India"
    }
    ]
};

export default info;


