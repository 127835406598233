<template>
  <div>
    <div class="home-page" :class="{ 'bg-white': !nightMode, 'bg-dark': nightMode }">
      <div class="section">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12 text-center">
              <img :src="picture" class="full-width" />
            </div>
            <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12 pt-5">
              <span
                class="home-title"
                :class="{ pgray: !nightMode }"
                ref="homeTitle"
              ></span>
              <div class="description-top-left">
                <p>
                  <span id="typed-text"></span>
                </p>
              </div>
              <div class="text-center pb-4">
               <!-- <button
                  class="btn btn-outline-secondary mx-2"
                  @click="open('linkedin')"
                  v-tooltip.bottom="'LinkedIn'"
                >
                  <i class="fab fa-linkedin"></i>
                </button>  -->

                <!-- <button
                  class="btn btn-outline-secondary mx-2"
                  @click="open('github')"
                  v-tooltip.bottom="'GitHub'"
                >
                  <i class="fab fa-github"></i>
                </button> -->

                <!-- <button
                  class="btn btn-outline-secondary mx-2"
                  @click="open('resume')"
                  v-tooltip.bottom="'Resume'"
                >
                  <i class="fa fa-file"></i>
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.home-page {
  background-image: url('@/assets/Image_res/user001.jpg');/* Replace with the path to your background image*/
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;

}
.home-page::before {
  content: '';
  position: absolute;
  /* top: 98%; 
  left: 95%; */
  border-style: groove;
  border-color:  rgb(255, 255, 255);
  border-width: 0.5px;
  width: 100%;
  height: 100%;
  background: rgba(200, 200, 200, 0.5);
  backdrop-filter: blur(1.5px); /* Adjust the blur amount as needed */
}

/* Add styles for full-width images */
.full-width {
  width: 100%;
  height: auto;
}

/* Apply styles to each section */
.section {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-title {
  font-size: 26px;
  font-weight: 500;
  text-align: right;
  position: relative;
  /* top: -290px; */
  left: 0px;
  padding: 1px;
  font-style: italic;
  text-shadow: 2px 2px 4px rgba(0.5, 0.5, 0.5, 5); /* Add a 3D shadow effect */
}

.description-top-left {
  text-align: right;
  position: relative;
  /* top: -220; */
  /* right: 0px; */
  left: 0;
  padding: 1px;
  /* letter-spacing: -1px; */
  font-size: 18px;
  font-weight: 200;
  font-style: italic;
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px 4px rgba(0.5, 0.5, 0.5, 5); /* Add a 3D shadow effect */
  
}
</style>

<script>
import info from "../../info";

export default {
  name: "Home",
  components: {},
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      picture: info.flat_picture,
      description: info.description,
      name: info.name,
      linkedin: info.links.linkedin,
      github: info.links.github,
      resume: info.links.resume,
      homeTitleText: "Hello There!",
    };
  },

  methods: {
  
    open(link) {
      switch (link) {
        case "linkedin":
          window.open(this.linkedin, "_blank");
          break;
        case "github":
          window.open(this.github, "_blank");
          break;
        case "resume":
          window.open(this.resume, "_blank");
          break;
      }
    },
    typeText(element, text) {
      return new Promise((resolve) => {
        const speed = 25; // Adjust the typing speed (milliseconds)
        let index = 0;

        function type() {
          if (index < text.length) {
            element.textContent += text.charAt(index);
            index++;
            setTimeout(type, speed);
          } else {
            resolve();
          }
        }

        type();
      });
    },
    async startTyping() {
      const homeTitle = this.$refs.homeTitle;
      const typedText = document.getElementById("typed-text");

      await this.typeText(homeTitle, this.homeTitleText);

      await this.typeText(typedText, this.description);
    },
  },
  mounted() {
    this.startTyping();
  },
};
</script>

<style scoped>
.home-title {
  padding: 5px;
  font-size: 38px;
  font-weight: 800;
  color: rgb(255, 255, 255);
}

/* img {
  max-width: 400px;
  margin-top: -200px;
  margin-left: -500px;
} */

/*screens larger than 580px */
@media only screen and (max-width: 580px) {
  .home-page {
    background-image: url('@/assets/Image_res/user001_mobi.jpg'); 
    background-position: cover;
  }
}


.fa {
  font-size: 15px;
  
}

.btn {
  border-color: #669db3ff;
  color: #669db3ff;
}

.btn:hover {
  background-color: #669db3ff;
  border-color: #669db3ff;
  color: white;
}

.btn:focus {
  background-color: #669db3ff;
  border-color: #669db3ff;
  color: white;
}

.btn:focus {
  outline: none !important;
}

p {
  text-align: justify;
  font-weight: 500;
}
</style>
